import { createApp } from 'vue';
import App from './App.vue';
import Vant from 'vant';

import 'vant/lib/index.css';
import 'lib-flexible/flexible';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';


const app = createApp(App);

// 使用 Vant 插件
app.use(Vant);

// 挂载应用
app.mount('#app');
