<template>
  <HomePage msg=""/>
</template>

<script>
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>

html {
  font-size: 50px;
}
body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}


</style>
